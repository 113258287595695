const CredentialFields = {
  credentialId: { type: 'id', label: 'Credential Id' },
  credentialName: { type: 'string', label: 'Credential Name*' },
  login: { type: 'string', label: 'Login*' },
  password: { type: 'string', label: 'Password*' },
  credentialType: { type: 'enum', label: 'Credential Type*' },
  encryptedTenantSecret: { type: 'string', label: 'Encrypted Tenant Secret*' },
  hashedTenantSecret: { type: 'string', label: 'Hashed Tenant Secret*' },
  tenantCertificateId: { type: 'id', label: 'Certificate*' },
  url: { type: 'string', label: 'Url*' },
  apiKey: { type: 'string', label: 'API Key*' },
  clientId: { type: 'string', label: 'Client ID*' },
  tenantId: { type: 'string', label: 'Tenant ID*' },
  clientSecret: { type: 'string', label: 'Client Secret*' }
};

export default CredentialFields;
