import React, { Component } from 'react';
import { Button, Col, Nav, NavItem, NavLink, Progress, Row } from 'reactstrap';
import s from 'components/CRUD/LegalHold/form/Wizard.module.scss';
import InputFormItem from 'components/FormItems/items/InputFormItem';
import SelectFormItem from 'components/FormItems/items/SelectFormItem';
import IniValues from 'components/FormItems/iniValues';
import PreparedValues from 'components/FormItems/preparedValues';
import Loader from 'components/Loader';
import { ErrorMessage, Formik } from 'formik';
import classnames from 'classnames';
import { Builder, Utils as QbUtils } from 'react-awesome-query-builder';
import ReportTemplateFields from './ReportTemplateFields';
import CronGenerator from './CronGenerator';
import * as Yup from 'yup';
import RadioFormItem from '../../FormItems/items/RadioFormItem';
import styles from '../Export/Export.module.scss';

const {
  queryBuilderFormat,
  jsonLogicFormat,
  queryString,
  mongodbFormat,
  sqlFormat,
  getTree,
  checkTree,
  loadTree,
  uuid,
  loadFromJsonLogic
} = QbUtils;

const count = 2;

class ReportForm extends Component {
  constructor(prop) {
    super(prop);
    this.state = {
      currentStep: 1,
      progress: 50,
      defaultValue: '30 5 * * 1,6',
      onDemand: false,
      scheduled: false,
      viaEmail: false,
      viaExport: false,
      scheduling: false,
      templates: [],
      fileLocations: [],
      cronValue: '',
      position: {
        top: 0,
        left: 0
      },
      emailPolicies: []
    };
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
  }

  FormValidationSchema = [
    Yup.object().shape({
      name: Yup.string().trim().required('Name* is a required field'),
      description: Yup.string().trim().required('Description* is a required field'),
      reportTemplateId: Yup.string().required('Templates* is a required field')
    }),
    Yup.object().shape({
      onDemand: Yup.boolean(),
      scheduled: Yup.boolean().test(
        'email-or-export',
        'Please select either On Demand or Scheduled',
        function (value) {
          return this.parent.onDemand || this.parent.scheduled;
        }
      ),
      viaEmail: Yup.boolean(),
      viaExport: Yup.boolean().when('scheduled', {
        is: true,
        then: () =>
          Yup.boolean().test(
            'email-or-export',
            'Please select either Email or File Export',
            function (value) {
              return this.parent.viaEmail || this.parent.viaExport;
            }
          ),
        otherwise: () => Yup.boolean().notRequired()
      }),
      reportOutputFileType: Yup.string().required('Report Format* is a required field'),
      reportEmailPolicyId: Yup.string().when('viaEmail', {
        is: true,
        then: () => Yup.string().required('Report Email Policy* is a required field'),
        otherwise: () => Yup.string().notRequired()
      }),
      reportLocationPolicyId: Yup.string().when('viaExport', {
        is: true,
        then: () => Yup.string().required('Report Location Policy* is a required field'),
        otherwise: () => Yup.string().notRequired()
      })
    })
  ];

  handleCronChange = (newValue) => {
    this.setState({
      cronValue: newValue,
      defaultValue: newValue
    });
  };

  componentDidMount() {
    this.setState({
      templates: this.props.templates,
      fileLocations: this.props.fileLocations,
      emailPolicies: this.props.emailPolicies
    });
  }

  renderBuilder = (props) => (
    <div className='query-builder-container mb-3'>
      <div className='query-builder qb-lite'>
        <Builder {...props} />
      </div>
    </div>
  );

  nextStep() {
    let currentStep = this.state.currentStep;
    if (currentStep >= count) {
      currentStep = count;
    } else {
      currentStep += 1;
    }

    this.setState({
      currentStep,
      progress: (100 / count) * currentStep
    });
  }

  previousStep() {
    let currentStep = this.state.currentStep;
    if (currentStep <= 1) {
      currentStep = 1;
    } else {
      currentStep -= 1;
    }

    this.setState({
      currentStep,
      progress: (100 / count) * currentStep
    });
  }

  iniValues = () => {
    const record = {};
    return IniValues(ReportTemplateFields, record);
  };

  handleSubmit = (values, actions) => {
    const { id, ...data } = PreparedValues(
      ReportTemplateFields,
      values || {},
      this.props.isEditing
    );
    data.reportOutputFileType = data.reportOutputFileType ? data.reportOutputFileType * 1 : null;
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
    const expireonUser = {
      firstName,
      lastName,
      email,
      userName
    };
    if (this.state.currentStep === count) {
      this.props.onSubmit({
        ...data,
        expireonUser,
        schedulerInterval: this.state.cronValue,
        reportDeliveryType: this.state?.reportDeliveryType,
        reportEmailPolicyId: this.state.viaEmail ? data.reportEmailPolicyId : null,
        reportLocationPolicyId: this.state.viaExport ? data.reportLocationPolicyId : null
      });
    } else {
      this.nextStep();
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  renameData = (data, name) => {
    const arr = [];
    if (name === 'templates') {
      if (Array.isArray(data)) {
        for (let key of data) {
          const obj = {};
          obj.value = key?.reportTemplateId;
          obj.label = `${key?.name}` + ` * ` + `${key?.description}`;
          arr.push(obj);
        }
      }
    }
    if (name === 'fileLocations') {
      if (Array.isArray(data)) {
        const filtered = data.filter((el) => el !== null);
        for (let key of filtered) {
          const obj = {};
          obj.value = key?.id;
          obj.label = `${key?.name}` + ` * ` + `${key?.location}`;
          arr.push(obj);
        }
      }
    }
    if (name === 'emailPolicies') {
      if (Array.isArray(data)) {
        for (let key of data) {
          const obj = {};
          obj.value = key?.id;
          obj.label = `${key?.name}` + ` * ` + `${key?.description}`;
          if (key?.recipients?.length > 0) {
            arr.push(obj);
          }
        }
      }
    }
    return arr;
  };

  firstStep = (form) => (
    <div>
      <InputFormItem name={'name'} schema={ReportTemplateFields} autoFocus />
      <InputFormItem name={'description'} schema={ReportTemplateFields} autoFocus />
      <div className='mt-2'>
        <SelectFormItem
          name={'reportTemplateId'}
          schema={ReportTemplateFields}
          placeholder={'Select Template'}
          mode={'fetch'}
          isClearable={false}
          data={this.renameData(this.state?.templates, 'templates')}
          isTemplate={true}
        />
        <ErrorMessage name='reportTemplateId'>
          {(msg) => <div className={styles.errorMessage}>{msg}</div>}
        </ErrorMessage>
      </div>
    </div>
  );

  secondStep = (form) => (
    <>
      <div>
        <Row>
          <Col>
            <div className={'fw-semi-bold mb-3'} style={{ marginBottom: '5px', marginTop: '10px' }}>
              Scheduling
            </div>
            <div className='d-flex align-items-center'>
              <span style={{ marginRight: '10px' }}>
                <input
                  type='radio'
                  name='scheduling'
                  id='onDemand'
                  className='form-check-input'
                  checked={this.state.onDemand}
                  onClick={() => {
                    form.setTouched({
                      ...form.values,
                      onDemand: true,
                      scheduled: true,
                      scheduling: true
                    });
                    form.setValues({
                      ...form.values,
                      onDemand: !form.values?.onDemand,
                      scheduled: false,
                      scheduling: 1
                    });
                    this.setState({
                      onDemand: true,
                      scheduled: false,
                      cronValue: null,
                      scheduling: form.touched.scheduling || false
                    });
                  }}
                />
              </span>
              <span>
                <label htmlFor='onDemand' className='mb-1'>
                  On Demand
                </label>
              </span>
            </div>
            <div className='d-flex align-items-center'>
              <span style={{ marginRight: '10px' }}>
                <input
                  type='radio'
                  name='scheduling'
                  id='scheduled'
                  className='form-check-input'
                  checked={this.state.scheduled}
                  onClick={() => {
                    form.setTouched({
                      ...form.values,
                      onDemand: true,
                      scheduled: true,
                      scheduling: true
                    });
                    form.setValues({
                      ...form.values,
                      onDemand: false,
                      scheduled: !form.values?.scheduled,
                      scheduling: 0
                    });
                    this.setState({
                      scheduled: true,
                      onDemand: false,
                      scheduling: form.touched.scheduling || false
                    });
                  }}
                />
              </span>
              <span>
                <label htmlFor='scheduled' className='mb-1'>
                  Scheduled
                </label>
              </span>
            </div>
            <ErrorMessage name='scheduled'>
              {(msg) => <div className={styles.errorMessage}>{msg}</div>}
            </ErrorMessage>
            {this.state?.scheduled && (
              <CronGenerator
                onValueChange={this.handleCronChange}
                defaultValue={this.state.defaultValue}
              />
            )}
          </Col>
        </Row>
      </div>
      <div className={'fw-semi-bold mb-3'} style={{ marginTop: '20px', marginBottom: '10px' }}>
        Settings
      </div>
      <div>
        <RadioFormItem name={'reportOutputFileType'} schema={ReportTemplateFields} />
        <ErrorMessage name='reportOutputFileType'>
          {(msg) => <div className={styles.errorMessage}>{msg}</div>}
        </ErrorMessage>
      </div>
      <div>
        <Row>
          <Col>
            <div className='d-flex align-items-center'>
              <span style={{ marginRight: '10px' }}>
                <input
                  type='radio'
                  name='fileType'
                  id='email'
                  className='form-check-input'
                  checked={this.state.viaEmail}
                  onClick={(e) => {
                    form.setTouched({
                      ...form.values,
                      viaEmail: true,
                      viaExport: true,
                      reportDeliveryType: true
                    });
                    form.setValues({
                      ...form.values,
                      viaEmail: !form.values?.viaEmail,
                      viaExport: false,
                      reportDeliveryType: 0
                    });
                    this.setState({
                      viaEmail: true,
                      viaExport: false,
                      reportDeliveryType: 0
                    });
                  }}
                />
              </span>
              <span>
                <label htmlFor='email' className='mb-1'>
                  Email
                </label>
              </span>
            </div>
            <div className='d-flex align-items-center'>
              <span style={{ marginRight: '10px' }}>
                <input
                  type='radio'
                  name='fileType'
                  id='fileExport'
                  className='form-check-input'
                  checked={this.state.viaExport}
                  onClick={(e) => {
                    form.setTouched({
                      ...form.values,
                      viaEmail: true,
                      viaExport: true,
                      reportDeliveryType: true
                    });
                    form.setValues({
                      ...form.values,
                      viaEmail: false,
                      viaExport: !form.values?.viaExport,
                      reportDeliveryType: 1
                    });
                    this.setState({
                      viaExport: true,
                      viaEmail: false,
                      reportDeliveryType: 1
                    });
                  }}
                />
              </span>
              <span>
                <label htmlFor='fileExport' className='mb-1'>
                  File Export
                </label>
              </span>
            </div>
            <ErrorMessage name='viaExport'>
              {(msg) => <div className={styles.errorMessage}>{msg}</div>}
            </ErrorMessage>
            {this.state?.viaEmail && (
              <>
                <SelectFormItem
                  name={'reportEmailPolicyId'}
                  schema={ReportTemplateFields}
                  placeholder={'Select Email Policy'}
                  mode={'fetch'}
                  isClearable={false}
                  data={this.renameData(this.state?.emailPolicies, 'emailPolicies')}
                  isTemplate={true}
                  emailPoliciesList={this.props?.emailPolicies}
                />
                <ErrorMessage name='reportEmailPolicyId'>
                  {(msg) => <div className={s.errorMessage}>{msg}</div>}
                </ErrorMessage>
              </>
            )}
            {this.state?.viaExport && (
              <>
                <SelectFormItem
                  name={'reportLocationPolicyId'}
                  schema={ReportTemplateFields}
                  placeholder={'Select Location Policy'}
                  mode={'fetch'}
                  isClearable={false}
                  data={this.renameData(this.state?.fileLocations, 'fileLocations')}
                  isTemplate={true}
                />
                <ErrorMessage name='reportLocationPolicyId'>
                  {(msg) => <div className={s.errorMessage}>{msg}</div>}
                </ErrorMessage>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );

  renderStepContent(currentStep, form) {
    switch (currentStep) {
      case 1:
        return this.firstStep(form);
      case 2:
        return this.secondStep(form);
      default:
        return this.firstStep(form);
    }
  }

  renderForm() {
    const { saveLoading } = this.props;
    const currentStep = this.state.currentStep;
    const validateStep = this.state.currentStep - 1;
    return (
      <div className={s.root}>
        <Row>
          <Col sm={12}>
            <Nav pills justified className={s.wizardNavigation}>
              <NavItem>
                <NavLink
                  className={classnames({
                    wizardActiveItem: currentStep === 1
                  })}
                >
                  <small>1.</small>
                  &nbsp; General
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    wizardActiveItem: currentStep === 2
                  })}
                >
                  <small>2.</small>
                  &nbsp; Settings
                </NavLink>
              </NavItem>
            </Nav>
            <Progress value={this.state.progress} className='progress-xs-wizard' />
            <div>
              <div className={s.stepBody}>
                <Formik
                  onSubmit={this.handleSubmit}
                  initialValues={this.iniValues()}
                  validationSchema={this.FormValidationSchema[validateStep]}
                >
                  {(form) => {
                    return (
                      <form onSubmit={form.handleSubmit}>
                        <div style={{ minHeight: '400px' }}>
                          {this.renderStepContent(this.state.currentStep, form)}
                        </div>
                        <div>
                          <ul className='pager wizard d-flex align-items-center justify-content-between w-100'>
                            <li className='previous'>
                              <Button
                                hidden={currentStep === 1}
                                color='primary'
                                onClick={this.previousStep}
                              >
                                <i className='fa fa-caret-left' />
                                &nbsp; <span className='second-subtitle-text'>Previous</span>
                              </Button>
                            </li>

                            <li className='next'>
                              <Button
                                color='primary'
                                onClick={form.handleSubmit}
                                disabled={saveLoading}
                              >
                                {currentStep === count ? (
                                  <span className='second-subtitle-text'>
                                    Save <i className='fa fa-check' />
                                  </span>
                                ) : (
                                  <span className='second-subtitle-text'>
                                    Next <i className='fa fa-caret-right' />
                                  </span>
                                )}
                              </Button>
                            </li>
                          </ul>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { isEditing, findLoading, record } = this.props;

    if (findLoading) {
      return <Loader />;
    }

    if (isEditing && !record) {
      return <Loader />;
    }

    return this.renderForm();
  }
}

export default ReportForm;
