const ReportTemplateFields = {
  name: {
    type: 'string',
    label: 'Name*',
    required: true
  },
  reportEmailPolicyId: {
    type: 'string',
    label: 'Report Email Policy*'
  },
  reportLocationPolicyId: {
    type: 'string',
    label: 'Report Location Policy*'
  },
  description: {
    type: 'string',
    label: 'Description*'
  },
  reportTemplateId: {
    type: 'string',
    label: 'Templates*'
  },
  scheduled: {
    type: 'boolean',
    label: 'Scheduled',
    required: true
  },
  onDemand: {
    type: 'boolean',
    label: 'On Demand',
    required: true
  },
  reportOutputFileType: {
    type: 'enum',
    label: 'Report Format*',
    required: true,
    options: [
      { value: '0', label: 'CSV' },
      { value: '1', label: 'PDF' }
    ]
  },
  viaEmail: {
    type: 'string',
    label: 'Email',
    required: true
  },
  viaExport: {
    type: 'string',
    label: 'File Export',
    required: true
  }
};

export default ReportTemplateFields;
