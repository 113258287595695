import React, { Component } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import { FormattingService } from 'utils/sizeFormatting';
import actions from 'actions/search/searchListActions';
import { connect } from 'react-redux';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import plusIcon from 'images/icons/plus.svg';
import SearchFormPage from '../form/SearchFormPage';
import viewIcon from 'images/icons/eye.svg';
import barsIcon from 'images/bars.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import Pagination from 'react-js-pagination';
import styles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import config from 'config';
import searchStyles from 'components/CRUD/Search/list/SearchTable.module.scss';
import searchIcon from 'images/icons/search.svg';
import { emptyDataMessage, customCloseBtn } from 'actions/common';
import { VscOpenPreview } from 'react-icons/vsc';
import caretDown from 'images/icons/caret-down.svg';

class SearchTable extends Component {
  intervalID = 0;
  state = {
    totalCount: 0,
    pageNumber: 1,
    pageSize: 5,
    searchString: '',
    paginationModalOpen: false,
    rows: [],
    dropdownItem: [],
    sortOrder: ''
  };

  openModal() {
    const { dispatch } = this.props;
    dispatch(actions.doOpenConfirm());
  }

  closeModal() {
    const { dispatch } = this.props;
    dispatch(actions.doCloseConfirm());
  }

  handleSearch = () => {
    const { dispatch } = this.props;
    this.setState({ pageNumber: 1 });
    dispatch(
      actions.doFetch({
        legalCaseId: this.props.legalCaseId,
        searchString: this.state.searchString,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        sortOrder: this.state.sortOrder
      })
    ).then(() => {
      this.setState({
        rows: this.props.rows,
        totalCount: this.props.count
      });
    });
  };

  showTiles() {
    this.props.dispatch(actions.doHideTable());
  }

  resetDropdowns(e) {
    if (e.target?.getAttribute('alt') != 'bar') {
      this.setState({
        dropdownItem: []
      });
    }
  }

  actionFormatter(cell, cell2, item) {
    const { caseStatus } = this.props;
    const isNotActive =
      caseStatus === config.caseStatus.Closed ||
      caseStatus === config.caseStatus.LegalHoldReleased ||
      caseStatus === config.caseStatus.Frozen;
    return (
      <Dropdown group isOpen={this.state.dropdownItem.includes(cell)} size='lg' toggle={() => {}}>
        <DropdownToggle className={'bg-transparent border-0'}>
          <img src={barsIcon} alt='bar' width={'24px'} height={'24px'} />
        </DropdownToggle>
        <DropdownMenu>
          {this.props.permissions['LegalUI.ContentSearches'] ? (
            <>
              <div>
                {!isNotActive && (
                  <button
                    className='btn bg-transparent text-white first-body-text d-flex align-items-center w-100'
                    type='button'
                    onClick={() => {
                      this.props.navigate(`/app/search/${cell}/${cell2}`);
                    }}
                  >
                    <VscOpenPreview
                      style={{
                        width: '16px',
                        height: '16px',
                        marginRight: '7px'
                      }}
                    />
                    <p className={'mb-0'}>Preview</p>
                  </button>
                )}
              </div>
              <div>
                <button
                  className='btn bg-transparent text-white first-body-text d-flex align-items-center w-100'
                  type='button'
                  onClick={() => {
                    this.props.navigate(`/app/search/${cell}`);
                  }}
                >
                  <img src={viewIcon} alt={'details'} className={'me-2'} />
                  <p className={'mb-0'}>Details</p>
                </button>
              </div>
            </>
          ) : null}
        </DropdownMenu>
      </Dropdown>
    );
  }

  refreshTable = () => {
    const { dispatch } = this.props;
    const interval = this.props?.refreshInterval.concat('000');
    const refreshInterval = JSON.parse(interval);
    this.intervalID = setInterval(() => {
      dispatch(
        actions.doFetch({
          legalCaseId: this.props.legalCaseId,
          searchString: this.state.searchString,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize,
          sortOrder: this.state.sortOrder
        })
      ).then(() => {
        this.setState({
          totalCount: this.props.count,
          rows: this.props.rows
        });
      });
    }, refreshInterval);
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      actions.doFetch({
        legalCaseId: this.props.legalCaseId,
        searchString: this.state.searchString,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        sortOrder: this.state.sortOrder
      })
    ).then(() => {
      this.setState({
        rows: this.props.rows,
        totalCount: this.props.count
      });
    });
    if (this.props.refreshInterval !== null) {
      this.refreshTable();
    }
    window.addEventListener('click', (e) => this.resetDropdowns(e));
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (prevProps.rows !== this.props.rows) {
      this.setState({
        rows: this.props.rows
      });
    }
    if (prevProps.refreshInterval !== this.props.refreshInterval) {
      this.refreshTable();
    }
    if (
      (prevState.searchString !== this.state.searchString && this.state.searchString === '') ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.sortOrder !== this.state.sortOrder
    ) {
      dispatch(
        actions.doFetch({
          legalCaseId: this.props.legalCaseId,
          searchString: this.state.searchString,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize,
          sortOrder: this.state.sortOrder
        })
      ).then(() => {
        this.setState({
          rows: this.props.rows,
          totalCount: this.props.count
        });
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    window.removeEventListener('click', (e) => this.resetDropdowns(e));
  }

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  sortFormatter(sortField) {
    const SortEnum = {
      name: 'name',
      creationDate: 'creationdate',
      totalItems: 'items',
      totalSize: 'size',
      hasAttachmentsCount: 'attachments',
      ['queryString.query']: 'query',
      expireonUser: 'createdby'
    };
    return SortEnum[sortField];
  }

  handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      this.setState({
        pageNumber: 1,
        sortOrder: this.sortFormatter(sortField).concat('_').concat(sortOrder)
      });
    }
  };

  render() {
    const toReadableSizeFormat = new FormattingService();

    const columns = [
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        formatter: (cell, row) => <div className={searchStyles.contentSearchName}>{cell}</div>
      },
      {
        dataField: 'creationDate',
        text: 'Created',
        sort: true,
        formatter: dataFormat.dateTimeFormatter
      },
      {
        dataField: 'totalItems',
        text: 'Items',
        sort: true,
        formatter: (cell, row) => toReadableSizeFormat.numberWithCommas(row?.totalItems)
      },
      {
        dataField: 'totalSize',
        text: 'Size',
        formatter: (cell, row) => dataFormat.sizeFormatter(row?.totalSize),
        sort: true
      },
      {
        dataField: 'hasAttachmentsCount',
        text: 'Attachments',
        sort: true,
        formatter: (item) => toReadableSizeFormat.numberWithCommas(item)
      },
      {
        dataField: 'queryString.query',
        text: 'Query',
        sort: true,
        formatter: (cell, row) => (
          <span>
            {row?.queryString?.query.length > 15
              ? row.queryString?.query.slice(0, 15) + '...'
              : row.queryString?.query}
          </span>
        )
      },
      {
        dataField: 'expireonUser',
        text: 'Created By',
        sort: true,
        formatter: (cell, row) => (
          <div>
            <span className='me-1'>{cell.firstName}</span>
            <span>{cell.lastName}</span>
          </div>
        )
      },
      {
        dataField: 'contentSearchId',
        text: 'Actions',
        csvExport: false,
        formatter: (cell, row) => {
          return this.actionFormatter.bind(this)(cell, row.legalCaseId, row);
        },
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (this.state.dropdownItem.includes(row.contentSearchId)) {
              this.setState({
                dropdownItem: []
              });
            } else {
              this.setState({
                dropdownItem: [row.contentSearchId]
              });
            }
          }
        }
      }
    ];
    return (
      <>
        <ToolkitProvider
          bootstrap4
          columns={columns}
          data={this.state.rows}
          keyField='contentSearchId'
        >
          {(props) => (
            <>
              <Row>
                <Col style={{ marginTop: '0px' }}>
                  <div className={'d-flex align-items-center'}>
                    <h3 className={'first-headline-text me-4 mb-0'}>Content Searches</h3>
                  </div>
                </Col>
              </Row>
              <Row key={'table-part'} className='mb-4'>
                <Col style={{ marginTop: '8px' }}>
                  <div className={'d-flex align-items-center'}>
                    {this.props.permissions['LegalUI.ContentSearches.Create'] &&
                    this.props?.caseStatus !== config.caseStatus.Closed ? (
                      <div>
                        <button
                          className='btn first-body-text d-flex align-items-center'
                          type='button'
                          onClick={() => {
                            this.openModal();
                          }}
                        >
                          <img src={plusIcon} alt={'plus'} className={'me-2'} />
                          <p className={'mb-0'}>New</p>
                        </button>
                      </div>
                    ) : null}
                  </div>
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '5px'
                  }}
                >
                  <button
                    className='btn fw-semi-bold d-flex align-items-center'
                    type='button'
                    onClick={() => this.showTiles()}
                    style={{ height: '37px', marginRight: '5px' }}
                  >
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </button>
                  <span>
                    <input
                      type='search'
                      placeholder={'Search'}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          this.handleSearch();
                        }
                      }}
                      style={{
                        border: '0.5px solid #0046b1',
                        borderRadius: '3px'
                      }}
                      className={'form-control search-input me-5 w-200'}
                      value={this.state.searchString}
                      onChange={(e) => {
                        this.setState({
                          searchString: e.target.value
                        });
                      }}
                    />
                  </span>
                  <span>
                    <button
                      className='btn search-button-with-icon me-2 ms-10'
                      type='button'
                      onClick={this.handleSearch}
                      style={{ height: '37px', width: '37px' }}
                    >
                      <span>
                        <img
                          title={'search'}
                          alt={'search'}
                          width={14}
                          height={14}
                          src={searchIcon}
                        />
                      </span>
                    </button>
                  </span>
                </Col>
              </Row>
              <div className='table-container'>
                <BootstrapTable
                  bordered={false}
                  classes={`table-striped table-hover fs-sm custom-table`}
                  remote={{
                    filter: false,
                    pagination: false,
                    sort: true,
                    cellEdit: false
                  }}
                  onTableChange={this.handleTableChange}
                  noDataIndication={emptyDataMessage}
                  {...props.baseProps}
                />
              </div>
              <Row key={'pagination'} className='mt-3'>
                <Col>
                  <p className={styles.totalCount}>Total: {this.state.totalCount}</p>
                </Col>
                <Col className='d-flex justify-content-end'>
                  {this.state.totalCount ? (
                    <Pagination
                      totalItemsCount={this.state.totalCount}
                      onChange={this.handlePageChange}
                      activePage={this.state.pageNumber}
                      itemsCountPerPage={this.state.pageSize}
                      pageRangeDisplayed={5}
                      prevPageText={'<'}
                      nextPageText={'>'}
                      firstPageText={'<<'}
                      lastPageText={'>>'}
                      linkClassFirst={styles.paginationNext}
                      linkClassPrev={styles.paginationNext}
                      linkClassNext={styles.paginationNext}
                      linkClassLast={styles.paginationNext}
                      linkClass={styles.pageLink}
                      activeLinkClass={styles.activeLinkClass}
                    />
                  ) : null}
                  <Dropdown
                    isOpen={this.state.paginationModalOpen}
                    toggle={() =>
                      this.setState({
                        paginationModalOpen: !this.state.paginationModalOpen
                      })
                    }
                    className={styles.pageSizeDropdown}
                  >
                    <DropdownToggle className='bg-transparent text-white d-flex'>
                      <p
                        style={{ marginBottom: '3px', marginRight: '2px' }}
                        className='first-body-text'
                      >
                        {this.state.pageSize}
                      </p>
                      <img
                        src={caretDown}
                        alt={'uncollapsed'}
                        width='20px'
                        height='20px'
                        className='ms-2'
                      />
                    </DropdownToggle>
                    <DropdownMenu className='dropdown-position-fixed'>
                      {[5, 10, 25].map((item) => (
                        <DropdownItem
                          className={styles.dropdownItem}
                          key={item}
                          onClick={() => {
                            this.setState({
                              pageSize: item,
                              paginationModalOpen: false,
                              pageNumber: 1
                            });
                          }}
                        >
                          {item}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </Col>
              </Row>
            </>
          )}
        </ToolkitProvider>
        <Modal
          size='lg'
          isOpen={this.props.modalOpen}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeModal())}>
            <p className='second-headline-text'>Create Content Search</p>
          </ModalHeader>
          <ModalBody>
            <SearchFormPage legalCaseId={this.props.legalCaseId} />
          </ModalBody>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    rows: store.search.list.rows,
    modalOpen: store.search.list.modalOpen,
    permissions: store.auth.permissions,
    count: store.search.list.count,
    refreshInterval: store.LegalHold.list.refreshInterval,
    currentTheme: store.layout.currentTheme
  };
}
export default connect(mapStateToProps)(SearchTable);
