import React from 'react';
import { TbFileTypeCsv } from 'react-icons/tb';
import { TbFileTypeHtml } from 'react-icons/tb';
import { TbFileTypeDocx } from 'react-icons/tb';
import { TbFileTypeDoc } from 'react-icons/tb';
import { TbFileTypePpt } from 'react-icons/tb';
import { TbFileTypePng } from 'react-icons/tb';
import { BsFiletypePptx } from 'react-icons/bs';
import { TbFileTypeJpg } from 'react-icons/tb';
import { TbFileTypePdf } from 'react-icons/tb';
import { TbFileTypeZip } from 'react-icons/tb';
import { TbFileTypeTxt } from 'react-icons/tb';
import { TbFileTypeXls } from 'react-icons/tb';
import { RiFileExcelLine } from 'react-icons/ri';
import { AiOutlineFileGif } from 'react-icons/ai';
import { MdOutlineInsertDriveFile } from 'react-icons/md';

export const MESSAGES = {
  LIMIT_REACHED:
    'Item Preview limit reached! Please use filter criteria to drill down on the content.'
};

export const emptyDataMessage = () => {
  return (
    <p
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        minWidth: '200px'
      }}
    >
      No data available
    </p>
  );
};

export const credentialTypeEnum = {
  CloudficientObjectStorage: '0',
  S3DataEncryption: '2',
  AIProvider: '3',
  SlackAttachmentStorage: '4',
  EntraApp: '5'
};

export const customCloseBtn = (closeModal) => (
  <button className='close' onClick={closeModal} type='button'>
    &times;
  </button>
);

export const fileIcons = (type) => {
  const iconStyle = {
    width: '20px',
    height: '20px',
    marginRight: '5px'
  };

  switch (type) {
    case 'csv':
      return <TbFileTypeCsv style={iconStyle} />;
    case 'doc':
      return <TbFileTypeDoc style={iconStyle} />;
    case 'docx':
      return <TbFileTypeDocx style={iconStyle} />;
    case 'gif':
      return <AiOutlineFileGif style={iconStyle} />;
    case 'html':
      return <TbFileTypeHtml style={iconStyle} />;
    case 'jpg':
      return <TbFileTypeJpg style={iconStyle} />;
    case 'pdf':
      return <TbFileTypePdf style={iconStyle} />;
    case 'png':
      return <TbFileTypePng style={iconStyle} />;
    case 'ppt':
      return <TbFileTypePpt style={iconStyle} />;
    case 'pptx':
      return <BsFiletypePptx style={iconStyle} />;
    case 'txt':
      return <TbFileTypeTxt style={iconStyle} />;
    case 'xls':
      return <TbFileTypeXls style={iconStyle} />;
    case 'xlsx':
      return <RiFileExcelLine style={iconStyle} />;
    case 'zip':
      return <TbFileTypeZip style={iconStyle} />;
    default:
      return <MdOutlineInsertDriveFile style={iconStyle} />;
  }
};
