const initialData = {
  filesList: [],
  directoriesList: []
};

export default (state = initialData, { type, payload }) => {
  if (type === 'LEGALHOLD_DIRECTORIES_LIST_FETCH_STARTED') {
    return {
      ...state,
      loading: true
    };
  }
  if (type === 'LEGALHOLD_DIRECTORIES_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      loading: false,
      directoriesList: payload.directoriesList
    };
  }
  if (type === 'LEGALHOLD_DIRECTORIES_LIST_FETCH_ERROR') {
    return {
      ...state,
      directoriesList: []
    };
  }
  if (type === 'LEGALHOLD_FILES_LIST_FETCH_STARTED') {
    return {
      ...state,
      loading: true
    };
  }
  if (type === 'LEGALHOLD_FILES_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      filesList: payload.filesList
    };
  }
  if (type === 'LEGALHOLD_FILES_LIST_FETCH_ERROR') {
    return {
      ...state,
      filesList: []
    };
  }
  return state;
};
