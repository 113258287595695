import React, { Component } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Widget from 'components/Widget/Widget';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Progress
} from 'reactstrap';
import { FormattingService } from 'utils/sizeFormatting';
import actions from 'actions/Export/ExportListActions';
import { connect } from 'react-redux';
import barsIcon from '../../../../images/bars.svg';
import formActions from 'actions/Export/ExportFormActions';
import ExportJobFormPage from '../form/ExportJobFormPage';
import plusIcon from 'images/icons/plus.svg';
import Pagination from 'react-js-pagination';
import styles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import exportStyles from 'components/CRUD/Export/Export.module.scss';
import config from 'config';
import syncIcon from '../../../../images/icons/sync.svg';
import closeIcon from '../../../../images/icons/close.svg';
import searchIcon from 'images/icons/search.svg';
import { emptyDataMessage, customCloseBtn } from 'actions/common';
import caretDown from 'images/icons/caret-down.svg';
import caretUp from 'images/icons/caret-up.svg';
import viewIcon from 'images/icons/eye.svg';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ExportListTable extends Component {
  state = {
    dropdownItem: [],
    totalCount: 0,
    searchString: '',
    pageNumber: 1,
    pageSize: 5,
    paginationModalOpen: false,
    resetModalOpen: false,
    selectedJob: null,
    newRows: [],
    legalCaseId: this.props?.legalCaseId || '',
    sortOrder: '',
    expandExport: false,
    failedItemsModal: false
  };

  exportProgressFormatter = (item) => {
    if (!item) {
      return null;
    }
    let value = Math.floor((item.exportedItems / item?.itemsRoutedForExport) * 100);
    if (!item.exportedItems) {
      return null;
    }
    return (
      <Progress animated={value < 100} value={value} style={{ position: 'relative' }}>
        <span
          style={{
            left: 'calc(100% / 2 - 10px)',
            position: 'absolute'
          }}
        >
          {value} %
        </span>
      </Progress>
    );
  };

  handleRetryFailedItems() {
    const { dispatch } = this.props;
    const legalCaseId = this.state.selectedJob?.legalCaseId;
    const exportJobId = this.state.selectedJob?.id;
    dispatch(actions.doRetryFailedItems(legalCaseId, exportJobId, this.props?.currentUser)).then(
      () => {
        this.setState({
          failedItemsModal: false
        });
        dispatch(
          actions.doFetch({
            searchString: this.state.searchString,
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize,
            legalCaseId: this.state.legalCaseId,
            sortOrder: this.state.sortOrder
          })
        ).then(() => {
          this.setState({
            totalCount: this.props.count,
            newRows: this.props.rows
          });
        });
      }
    );
  }

  closeFailedItemsModal() {
    this.setState({
      failedItemsModal: !this.state.failedItemsModal
    });
  }

  handleResetQueuedItems() {
    const { dispatch } = this.props;
    dispatch(actions.doResetFailedItems(this.state.selectedJob.id, this.props?.currentUser)).then(
      () => {
        this.setState({
          resetModalOpen: false
        });
        dispatch(
          actions.doFetch({
            searchString: this.state.searchString,
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize,
            legalCaseId: this.state.legalCaseId,
            sortOrder: this.state.sortOrder
          })
        ).then(() => {
          this.setState({
            totalCount: this.props.count,
            newRows: this.props.rows
          });
        });
      }
    );
  }

  closeResetModal() {
    this.setState({
      resetModalOpen: !this.state.resetModalOpen
    });
  }

  actionFormatter(cell, row) {
    return (
      <Dropdown group isOpen={this.state.dropdownItem.includes(cell)} size='lg' toggle={() => {}}>
        <DropdownToggle className={'bg-transparent border-0'}>
          <img src={barsIcon} alt='bar' width={'24px'} height={'24px'} />
        </DropdownToggle>
        <DropdownMenu>
          {this.props?.caseStatus !== config.caseStatus.Closed && (
            <button
              disabled={!row?.failedItems}
              className='btn bg-transparent text-white fw-semi-bold d-flex align-items-center'
              type='button'
              onClick={() => {
                this.setState({
                  failedItemsModal: true,
                  selectedJob: row
                });
              }}
            >
              <img src={syncIcon} alt={'sync'} width={'16px'} height={'16px'} className={'me-2 '} />
              <p className={'mb-0 first-body-text'}>Retry failed items</p>
            </button>
          )}
          {this.props?.caseStatus !== config.caseStatus.Closed && (
            <button
              disabled={row?.isExported || row?.totalItems === 0 || row?.itemsRoutedForExport === 0}
              className='btn bg-transparent text-white fw-semi-bold d-flex align-items-center'
              type='button'
              onClick={() => {
                this.setState({
                  resetModalOpen: true,
                  selectedJob: row
                });
              }}
            >
              <img
                src={closeIcon}
                alt={'sync'}
                width={'16px'}
                height={'16px'}
                className={'me-2 '}
              />
              <p className={'mb-0 first-body-text'}>Reset queued items</p>
            </button>
          )}
          {this.props.permissions['LegalUI.Export'] && (
            <button
              className='btn bg-transparent text-white fw-semi-bold d-flex align-items-center w-100'
              type='button'
              onClick={() => {
                this.props.navigate(`/app/Export/${cell}`);
              }}
            >
              <img
                src={viewIcon}
                alt={'details'}
                width={'16px'}
                height={'16px'}
                className={'me-2'}
              />
              <p className={'mb-0 first-body-text'}>Details</p>
            </button>
          )}
        </DropdownMenu>
      </Dropdown>
    );
  }

  resetDropdowns(e) {
    if (e.target?.getAttribute('alt') !== 'bar') {
      this.setState({
        dropdownItem: []
      });
    }
  }

  openModal() {
    const { dispatch } = this.props;
    dispatch(formActions.doOpenConfirm());
  }

  closeModal() {
    const { dispatch } = this.props;
    dispatch(formActions.doCloseConfirm());
  }

  handleSearch = () => {
    const { dispatch } = this.props;
    this.setState({
      pageNumber: 1
    });
    dispatch(
      actions.doFetch({
        searchString: this.state.searchString,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        legalCaseId: this.state.legalCaseId,
        sortOrder: this.state.sortOrder
      })
    ).then(() => {
      this.setState({
        totalCount: this.props.count,
        newRows: this.props.rows
      });
    });
  };

  refreshTable = () => {
    const { dispatch } = this.props;
    const interval = this.props?.refreshInterval.concat('000');
    const refreshInterval = JSON.parse(interval);
    this.intervalID = setInterval(() => {
      dispatch(
        actions.doFetch({
          legalCaseId: this.state.legalCaseId,
          searchString: this.state.searchString,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize,
          sortOrder: this.state.sortOrder
        })
      ).then(() => {
        this.setState({
          totalCount: this.props.count,
          newRows: this.props.rows
        });
      });
    }, refreshInterval);
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      actions.doFetch({
        searchString: this.state.searchString,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        legalCaseId: this.state.legalCaseId,
        sortOrder: this.state.sortOrder
      })
    ).then(() => {
      this.setState({
        totalCount: this.props.count,
        newRows: this.props.rows
      });
    });
    window.addEventListener('click', (e) => this.resetDropdowns(e));
    if (this.props?.refreshInterval && this.props?.refreshInterval !== null) {
      this.refreshTable();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (
      (prevState.searchString !== this.state.searchString && this.state.searchString === '') ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.sortOrder !== this.state.sortOrder
    ) {
      dispatch(
        actions.doFetch({
          searchString: this.state.searchString,
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber,
          legalCaseId: this.state.legalCaseId,
          sortOrder: this.state.sortOrder
        })
      ).then(() => {
        this.setState({
          newRows: this.props.rows,
          totalCount: this.props.count
        });
      });
    }
    if (prevProps.rows !== this.props.rows) {
      this.setState({
        newRows: this.props.rows,
        totalCount: this.props.count
      });
    }
    if (prevProps.refreshInterval !== this.props.refreshInterval) {
      this.refreshTable();
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    window.removeEventListener('click', (e) => this.resetDropdowns(e));
  }

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  sortFormatter(sortField) {
    const SortEnum = {
      name: 'name',
      created: 'created',
      totalItems: 'totalitems',
      exportedItems: 'exported',
      totalSizeInBytes: 'totalsize',
      itemsRoutedForExport: 'itemsroutedforexport',
      exportPolicyName: 'exportpolicyname',
      exportStatus: 'status',
      statusMessage: 'statusMessage',
      failedItems: 'faileditems',
      exportFinishedTimestamp: 'exportfinishedtimestamp'
    };
    return SortEnum[sortField];
  }

  handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      this.setState({
        pageNumber: 1,
        sortOrder: this.sortFormatter(sortField).concat('_').concat(sortOrder)
      });
    }
  };

  toggleExport = () => {
    this.setState({
      expandExport: !this.state.expandExport
    });
  };

  exportMenu = () => {
    return (
      <Dropdown
        isOpen={this.state.expandExport}
        className={`${styles.exportButton}`}
        toggle={this.toggleExport}
      >
        <DropdownToggle
          className={`${styles.exportButton} bg-transparent text-white mb-0`}
          disabled={this.state.totalCount === 0}
        >
          Export
          {!this.state.expandExport ? (
            <img src={caretDown} alt={'uncollapsed'} width='20px' height='20px' className='ms-2' />
          ) : (
            <img src={caretUp} alt={'collapsed'} width='20px' height='20px' className='ms-2' />
          )}
        </DropdownToggle>
        <DropdownMenu className='p-0 bg-transparent'>
          <DropdownItem className={`${styles.exportItem}`} onClick={this.handleExportCSV}>
            CSV
          </DropdownItem>
          <DropdownItem className={`${styles.exportItem}`} onClick={this.handleExportPDF}>
            PDF
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  columnsToExport = [
    'name',
    'exportPolicyName',
    'created',
    'totalItems',
    'exportedItems',
    'totalSizeInBytes',
    'itemsRoutedForExport',
    'failedItems',
    'statusMessage',
    'exportStatus',
    'exportFinishedTimestamp'
  ];

  handleExportCSV = () => {
    const { dispatch } = this.props;
    const url = `${config.externalApi}/legal/api/ExportJob/ExportToCsv?`;
    const currentUser = this.props?.currentUser;
    const columnHeaders = this.columnsToExport.join(',');
    dispatch(
      actions.handleExportToCSV(
        url,
        currentUser,
        this.state.legalCaseId,
        this.state.searchString,
        columnHeaders
      )
    );
  };

  handleExportPDF = () => {
    const { dispatch } = this.props;
    const url = `${config.externalApi}/legal/api/ExportJob/ExportToPdf?`;
    const currentUser = this.props?.currentUser;
    const columnHeaders = this.columnsToExport.join(',');
    dispatch(
      actions.handleExportToPDF(
        url,
        currentUser,
        this.state.legalCaseId,
        this.state.searchString,
        columnHeaders
      )
    );
  };

  redirectToCase = (row) => {
    const { dispatch } = this.props;
    if (!this.props.legalCaseId) {
      this.props.navigate(`/app/LegalCase/${row?.legalCaseId}`);
    }
  };

  showTiles() {
    const { dispatch } = this.props;
    dispatch(actions.doHideTable());
  }

  render() {
    const toReadableSizeFormat = new FormattingService();

    const columns = [
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        formatter: (cell, row) => <div className={exportStyles.exportName}>{cell}</div>,
        events: {
          onClick: (e, column, columnIndex, row) => this.redirectToCase(row)
        }
      },
      {
        dataField: 'exportPolicyName',
        text: 'Export Policy',
        sort: true,
        formatter: (cell, row) => <div className={exportStyles.exportName}>{cell}</div>,
        events: {
          onClick: (e, column, columnIndex, row) => this.redirectToCase(row)
        }
      },
      {
        dataField: 'created',
        text: 'Created',
        formatter: dataFormat.dateTimeFormatter,
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => this.redirectToCase(row)
        }
      },
      {
        dataField: 'exportFinishedTimestamp',
        text: 'Export Finished',
        formatter: dataFormat.dateTimeFormatter,
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => this.redirectToCase(row)
        }
      },
      {
        dataField: 'totalItems',
        text: 'Total Count',
        sort: true,
        formatter: (item) => toReadableSizeFormat.numberWithCommas(item),
        events: {
          onClick: (e, column, columnIndex, row) => this.redirectToCase(row)
        }
      },
      {
        dataField: 'exportedItems',
        text: 'Exported Items',
        sort: true,
        formatter: (item) => toReadableSizeFormat.numberWithCommas(item),
        events: {
          onClick: (e, column, columnIndex, row) => this.redirectToCase(row)
        }
      },
      {
        dataField: 'totalSizeInBytes',
        text: 'Size',
        sort: true,
        formatter: (item) => dataFormat.sizeFormatter(item),
        events: {
          onClick: (e, column, columnIndex, row) => this.redirectToCase(row)
        }
      },
      {
        dataField: 'itemsRoutedForExport',
        text: 'Items Expected After Export',
        sort: true,
        formatter: (item) => toReadableSizeFormat.numberWithCommas(item),
        events: {
          onClick: (e, column, columnIndex, row) => this.redirectToCase(row)
        }
      },
      {
        dataField: 'failedItems',
        text: 'Failed Items',
        sort: true,
        formatter: (item) => toReadableSizeFormat.numberWithCommas(item),
        events: {
          onClick: (e, column, columnIndex, row) => this.redirectToCase(row)
        }
      },
      {
        dataField: 'exportStatus',
        text: 'Status',
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => this.redirectToCase(row)
        }
      },
      {
        dataField: 'statusMessage',
        text: 'Status Message',
        sort: true,
        formatter: (cell, row) => <div className={exportStyles.exportName}>{cell}</div>,
        events: {
          onClick: (e, column, columnIndex, row) => this.redirectToCase(row)
        }
      },
      {
        dataField: 'exportProgress',
        text: 'Export Progress',
        formatter: (cell, row) => {
          return this.exportProgressFormatter.bind(this)(row);
        },
        events: {
          onClick: (e, column, columnIndex, row) => this.redirectToCase(row)
        }
      },
      {
        dataField: 'id',
        text: 'Actions',
        formatter: (cell, row) => {
          return this.actionFormatter.bind(this)(cell, row);
        },
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (this.state.dropdownItem.includes(row.id)) {
              this.setState({
                dropdownItem: []
              });
            } else {
              this.setState({
                dropdownItem: [row.id]
              });
            }
          }
        }
      }
    ];
    return (
      <>
        <Widget
          style={{
            marginTop: '20px',
            width: '100%'
          }}
        >
          <ToolkitProvider bootstrap4 columns={columns} data={this.state.newRows} keyField='id'>
            {(props) => (
              <>
                <Row>
                  <Col style={{ marginTop: '0px', marginBottom: '10px' }}>
                    <div className={'d-flex align-items-center'}>
                      <h3 className={'first-headline-text me-4 mb-0'}>Export Jobs</h3>
                    </div>
                  </Col>
                </Row>
                <Row key={'table-part'} className='mb-4'>
                  <Col style={{ marginTop: '8px' }}>
                    {this.props.permissions['LegalUI.Export.Create'] &&
                    this.props?.caseStatus !== config.caseStatus.Closed ? (
                      <div className={'d-flex align-items-center'}>
                        {this.props.legalCaseId && (
                          <button
                            className='btn first-body-text d-flex align-items-center'
                            type='button'
                            onClick={() => this.openModal()}
                          >
                            <img src={plusIcon} alt={'plus'} className={'me-2'} />
                            <p className={'mb-0'}>New</p>
                          </button>
                        )}
                      </div>
                    ) : null}
                  </Col>
                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '5px'
                    }}
                  >
                    <div style={{ marginRight: '5px' }}>{this.exportMenu()}</div>
                    <span>
                      {this.props.legalCaseId && (
                        <button
                          className='btn fw-semi-bold d-flex align-items-center'
                          type='button'
                          onClick={() => this.showTiles()}
                          style={{ height: '37px', marginRight: '5px' }}
                        >
                          <FontAwesomeIcon icon={faEllipsisH} />
                        </button>
                      )}
                    </span>
                    <span>
                      <input
                        type='search'
                        placeholder={'Search'}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            this.handleSearch();
                          }
                        }}
                        style={{
                          border: '0.5px solid #0046b1',
                          borderRadius: '0'
                        }}
                        className={'form-control search-input me-5 w-200'}
                        value={this.state.searchString}
                        onChange={(e) => {
                          this.setState({
                            searchString: e.target.value
                          });
                        }}
                      />
                    </span>
                    <span>
                      <button
                        style={{
                          borderRadius: '0',
                          height: '37px',
                          width: '37px'
                        }}
                        className='btn me-2 ms-10'
                        type='button'
                        onClick={this.handleSearch}
                      >
                        <img
                          title={'search'}
                          alt={'search'}
                          width={14}
                          height={14}
                          src={searchIcon}
                        />
                      </button>
                    </span>
                  </Col>
                </Row>
                <div className='table-container'>
                  <BootstrapTable
                    bordered={false}
                    classes={`table-striped table-hover fs-sm custom-table`}
                    remote={{
                      filter: false,
                      pagination: false,
                      sort: true,
                      cellEdit: false
                    }}
                    onTableChange={this.handleTableChange}
                    noDataIndication={emptyDataMessage}
                    rowStyle={{
                      cursor: !this.props?.legalCaseId ? 'pointer' : ''
                    }}
                    {...props.baseProps}
                  />
                </div>
                <Row key={'pagination'} className='mt-3'>
                  <Col>
                    <p className={styles.totalCount}>Total: {this.state.totalCount}</p>
                  </Col>
                  <Col className='d-flex justify-content-end'>
                    {this.state.totalCount ? (
                      <Pagination
                        totalItemsCount={this.state.totalCount}
                        onChange={this.handlePageChange}
                        activePage={this.state.pageNumber}
                        itemsCountPerPage={this.state.pageSize}
                        pageRangeDisplayed={5}
                        prevPageText={'<'}
                        nextPageText={'>'}
                        firstPageText={'<<'}
                        lastPageText={'>>'}
                        linkClassFirst={styles.paginationNext}
                        linkClassPrev={styles.paginationNext}
                        linkClassNext={styles.paginationNext}
                        linkClassLast={styles.paginationNext}
                        linkClass={styles.pageLink}
                        activeLinkClass={styles.activeLinkClass}
                      />
                    ) : null}
                    <Dropdown
                      isOpen={this.state.paginationModalOpen}
                      toggle={() =>
                        this.setState({
                          paginationModalOpen: !this.state.paginationModalOpen
                        })
                      }
                      className={styles.pageSizeDropdown}
                    >
                      <DropdownToggle className='bg-transparent text-white d-flex'>
                        <p
                          style={{ marginBottom: '3px', marginRight: '2px' }}
                          className='first-body-text'
                        >
                          {this.state.pageSize}
                        </p>
                        <img
                          src={caretDown}
                          alt={'uncollapsed'}
                          width='20px'
                          height='20px'
                          className='ms-2'
                        />
                      </DropdownToggle>
                      <DropdownMenu className='dropdown-position-fixed'>
                        {[5, 10, 25].map((item) => (
                          <DropdownItem
                            className={styles.dropdownItem}
                            key={item}
                            onClick={() => {
                              this.setState({
                                pageSize: item,
                                paginationModalOpen: false,
                                pageNumber: 1
                              });
                            }}
                          >
                            {item}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
                <Modal
                  size='md'
                  isOpen={this.props.modalOpen}
                  className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
                >
                  <ModalHeader close={customCloseBtn(() => this.closeModal())}>
                    <p className='second-headline-text'>Create export job</p>
                  </ModalHeader>
                  <ModalBody>
                    <ExportJobFormPage legalCaseId={this.props.legalCaseId} />
                  </ModalBody>
                </Modal>
              </>
            )}
          </ToolkitProvider>
        </Widget>
        <Modal
          size='md'
          isOpen={this.state.resetModalOpen}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeResetModal())}>
            <p className='second-headline-text'>Confirm</p>
          </ModalHeader>
          <ModalBody>
            <p className='text-center'>
              Do you want to reset queued items for {this.state?.selectedJob?.name} export job?
            </p>
            <div className='d-flex justify-content-center align-content-center'>
              <button
                type='button'
                className='btn secondary-btn first-body-text me-3'
                onClick={() => this.closeResetModal()}
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn first-body-text'
                onClick={() => this.handleResetQueuedItems()}
              >
                Reset
              </button>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          size='md'
          isOpen={this.state.failedItemsModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeFailedItemsModal())}>
            <p className='second-headline-text'>Confirm</p>
          </ModalHeader>
          <ModalBody>
            <p className='text-center'>
              Do you want to retry failed items for {this.state?.selectedJob?.name} export job?
            </p>
            <div className='d-flex justify-content-center align-content-center'>
              <button
                type='button'
                className='btn secondary-btn first-body-text me-3'
                onClick={() => this.closeFailedItemsModal()}
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn first-body-text'
                onClick={() => this.handleRetryFailedItems()}
              >
                Retry
              </button>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    rows: store.Export.list.rows,
    modalOpen: store.Export.form.modalOpen,
    count: store.Export.list.count,
    permissions: store.auth.permissions,
    refreshInterval: store.LegalHold.list.refreshInterval,
    currentUser: store.auth.currentUser,
    currentTheme: store.layout.currentTheme
  };
}
export default connect(mapStateToProps)(ExportListTable);
