import React, { Component } from 'react';
import LegalHoldListTable from 'components/CRUD/LegalHold/list/LegalHoldListTable';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import LegalHoldTilesView from './LegalHoldTilesView';
import { withRouter } from 'utils/withRouter';
import Widget from 'components/Widget/Widget';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import s from 'components/CRUD/Search/itemContent/styles.module.scss';
import ChatLegalHoldListTable from '../chatLegalHold/ChatLegalHoldListTable';
import ChatLegalHoldTilesView from '../chatLegalHold/ChatLegalHoldTilesView';

class LegalHoldListPage extends Component {
  state = {
    defaultTabsTabId: '1'
  };

  changeTab(field, id) {
    this.setState({
      [field]: id
    });
  }

  render() {
    return (
      <div>
        <Widget style={{ marginTop: '20px' }}>
          <Row>
            <Col style={{ marginTop: '0px', marginBottom: '10px' }}>
              <div className={'d-flex align-items-center'}>
                <h3 className={'first-headline-text me-4 mb-0'}>Legal Holds</h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Nav tabs className='bg-transparent d-flex mt'>
              <NavItem
                className={this.state.defaultTabsTabId === '1' ? s.navItem : s.linkText}
                style={{ marginLeft: '8px' }}
              >
                <NavLink
                  className={this.state.defaultTabsTabId === '1' ? s.navLink : s.linkText}
                  onClick={() => {
                    this.changeTab('defaultTabsTabId', '1');
                  }}
                >
                  Mail
                </NavLink>
              </NavItem>
              <NavItem className={this.state.defaultTabsTabId === '2' ? s.navItem : s.linkText}>
                <NavLink
                  className={this.state.defaultTabsTabId === '2' ? s.navLink : s.linkText}
                  onClick={() => {
                    this.changeTab('defaultTabsTabId', '2');
                  }}
                >
                  Chat
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className='mb-xlg' activeTab={this.state.defaultTabsTabId}>
              {this.state.defaultTabsTabId === '1' && (
                <TabPane className={s.tabPane} tabId='1'>
                  {this.props.permissions['LegalUI.LegalHold'] ? (
                    <>
                      {this.props.showTableView ? (
                        <LegalHoldListTable
                          legalCaseId={this.props.legalCaseId}
                          caseStatus={this.props.caseStatus}
                          navigate={this.props.navigate}
                        />
                      ) : (
                        <LegalHoldTilesView
                          legalCaseId={this.props.legalCaseId}
                          caseStatus={this.props.caseStatus}
                          navigate={this.props.navigate}
                        />
                      )}
                    </>
                  ) : (
                    <Navigate to='/no-access' replace />
                  )}
                </TabPane>
              )}
              {this.state.defaultTabsTabId === '2' && (
                <TabPane className={s.tabPane} tabId='2'>
                  {this.props.permissions['LegalUI.LegalHold.ChatLegalHold'] ? (
                    <>
                      {this.props.showTableView ? (
                        <ChatLegalHoldListTable
                          legalCaseId={this.props.legalCaseId}
                          caseStatus={this.props.caseStatus}
                          navigate={this.props.navigate}
                        />
                      ) : (
                        <ChatLegalHoldTilesView
                          legalCaseId={this.props.legalCaseId}
                          caseStatus={this.props.caseStatus}
                          navigate={this.props.navigate}
                        />
                      )}
                    </>
                  ) : (
                    <Navigate to='/no-access' replace />
                  )}
                </TabPane>
              )}
            </TabContent>
          </Row>
        </Widget>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    permissions: store.auth.permissions,
    showTableView: store.LegalHold.list.showTableView
  };
}
export default withRouter(connect(mapStateToProps)(LegalHoldListPage));
