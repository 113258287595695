const initialData = {
  rows: [],
  loading: false,
  count: 0,
  refreshInterval: null,
  showTableView: true,
  chatLegalHoldsList: [],
  chatCount: 0
};

export default (state = initialData, { type, payload }) => {
  if (type === 'LEGALHOLD_LIST_FETCH_STARTED') {
    return {
      ...state,
      loading: true
    };
  }

  if (type === 'LEGALHOLD_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
      count: payload.count
    };
  }

  if (type === 'LEGALHOLD_LIST_FETCH_ERROR') {
    return {
      ...state,
      loading: false,
      rows: []
    };
  }

  if (type === 'LEGALHOLD_LIST_DELETE_STARTED') {
    return {
      ...state,
      loading: true
    };
  }

  if (type === 'LEGALHOLD_LIST_DELETE_SUCCESS') {
    return {
      ...state,
      loading: false
    };
  }

  if (type === 'LEGALHOLD_LIST_DELETE_ERROR') {
    return {
      ...state,
      loading: false
    };
  }

  if (type === 'REFRESH_INTERVAL_FETCH_STARTED') {
    return {
      ...state
    };
  }

  if (type === 'REFRESH_INTERVAL_FETCH_SUCCESS') {
    return {
      ...state,
      refreshInterval: payload.refreshInterval
    };
  }

  if (type === 'REFRESH_INTERVAL_FETCH_ERROR') {
    return {
      ...state,
      refreshInterval: null
    };
  }

  if (type === 'LEGALHOLD_EXPORT_STARTED') {
    return {
      ...state
    };
  }

  if (type === 'LEGAL_HOLD_LIST_SHOW_TABLE_CONFIRM') {
    return {
      ...state,
      showTableView: true
    };
  }

  if (type === 'LEGAL_HOLD_LIST_HIDE_TABLE_CONFIRM') {
    return {
      ...state,
      showTableView: false
    };
  }

  if (type === 'CHAT_LEGALHOLD_LIST_FETCH_STARTED') {
    return {
      ...state,
      loading: true
    };
  }

  if (type === 'CHAT_LEGALHOLD_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      loading: false,
      chatLegalHoldsList: payload.rows,
      chatCount: payload.count
    };
  }

  if (type === 'CHAT_LEGALHOLD_LIST_FETCH_ERROR') {
    return {
      ...state,
      loading: false,
      chatLegalHoldsList: [],
      chatCount: 0
    };
  }

  return state;
};
