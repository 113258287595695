import { defaultOptions } from 'services/userService';
import Errors from 'components/FormItems/error/errors';
import config from 'config';

async function getDirectoriesHierarchy(id) {
  const response = await fetch(
    `${config.externalApi}/legal/api/OneDriveLegalHold/Hierarchy?legalHoldId=${id}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const data = await response.json();
  return data;
}

async function getFilesList(path) {
  const response = await fetch(
    `${config.externalApi}/legal/api/OneDriveLegalHold/Files?folderPath=${path}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const data = await response.json();
  return data;
}

const actions = {
  doGetDirectoriesHierarchy: (id) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALHOLD_DIRECTORIES_LIST_FETCH_STARTED'
      });

      const response = await getDirectoriesHierarchy(id);
      dispatch({
        type: 'LEGALHOLD_DIRECTORIES_LIST_FETCH_SUCCESS',
        payload: {
          directoriesList: response
        }
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: 'LEGALHOLD_DIRECTORIES_LIST_FETCH_ERROR'
      });
    }
  },
  doGetFilesList: (path) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALHOLD_FILES_LIST_FETCH_STARTED'
      });

      const response = await getFilesList(path);
      dispatch({
        type: 'LEGALHOLD_FILES_LIST_FETCH_SUCCESS',
        payload: {
          filesList: response
        }
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: 'LEGALHOLD_FILES_LIST_FETCH_ERROR'
      });
    }
  }
};

export default actions;
