const initialData = {
  legalCasesStats: {},
  legalHoldsStats: {},
  contentSearchesStats: {},
  exportJobsStats: {},
  legalCasesPerStatus: {},
  exportJobsByIsExported: {},
  exportStatsByMonth: [],
  authorizedUsersList: []
};

export default (state = initialData, { type, payload }) => {
  if (type === 'LEGAL_CASES_COUNT_FETCH_STARTED') {
    return {
      ...state
    };
  }

  if (type === 'LEGAL_CASES_COUNT_FETCH_SUCCESS') {
    return {
      ...state,
      legalCasesStats: payload
    };
  }

  if (type === 'LEGAL_CASES_COUNT_FETCH_ERROR') {
    return {
      ...state,
      legalCasesStats: {}
    };
  }
  if (type === 'LEGAL_HOLDS_COUNT_FETCH_STARTED') {
    return {
      ...state
    };
  }

  if (type === 'LEGAL_HOLDS_COUNT_FETCH_SUCCESS') {
    return {
      ...state,
      legalHoldsStats: payload
    };
  }

  if (type === 'LEGAL_HOLDS_COUNT_FETCH_ERROR') {
    return {
      ...state,
      legalHoldsStats: {}
    };
  }

  if (type === 'CONTENT_SEARCHES_COUNT_FETCH_STARTED') {
    return {
      ...state
    };
  }

  if (type === 'CONTENT_SEARCHES_COUNT_FETCH_SUCCESS') {
    return {
      ...state,
      contentSearchesStats: payload
    };
  }

  if (type === 'CONTENT_SEARCHES_COUNT_FETCH_ERROR') {
    return {
      ...state,
      contentSearchesStats: {}
    };
  }

  if (type === 'EXPORT_JOBS_COUNT_FETCH_STARTED') {
    return {
      ...state
    };
  }

  if (type === 'EXPORT_JOBS_COUNT_FETCH_SUCCESS') {
    return {
      ...state,
      exportJobsStats: payload
    };
  }

  if (type === 'EXPORT_JOBS_COUNT_FETCH_ERROR') {
    return {
      ...state,
      exportJobsStats: {}
    };
  }

  if (type === 'LEGAL_CASES_STATUS_FETCH_STARTED') {
    return {
      ...state
    };
  }

  if (type === 'LEGAL_CASES_STATUS_FETCH_SUCCESS') {
    return {
      ...state,
      legalCasesPerStatus: payload
    };
  }

  if (type === 'LEGAL_CASES_STATUS_FETCH_ERROR') {
    return {
      ...state,
      legalCasesPerStatus: null
    };
  }

  if (type === 'EXPORT_JOBS_STATUS_FETCH_STARTED') {
    return {
      ...state
    };
  }

  if (type === 'EXPORT_JOBS_STATUS_FETCH_SUCCESS') {
    return {
      ...state,
      exportJobsByIsExported: payload
    };
  }

  if (type === 'EXPORT_JOBS_STATUS_FETCH_ERROR') {
    return {
      ...state,
      exportJobsByIsExported: null
    };
  }

  if (type === 'EXPORT_STATS_BY_MONTH_FETCH_STARTED') {
    return {
      ...state
    };
  }

  if (type === 'EXPORT_STATS_BY_MONTH_FETCH_SUCCESS') {
    return {
      ...state,
      exportStatsByMonth: payload
    };
  }

  if (type === 'EXPORT_STATS_BY_MONTH_FETCH_ERROR') {
    return {
      ...state,
      exportStatsByMonth: []
    };
  }

  if (type === 'AUTHORIZED_USERS_FETCH_STARTED') {
    return {
      ...state
    };
  }

  if (type === 'AUTHORIZED_USERS_FETCH_SUCCESS') {
    return {
      ...state,
      authorizedUsersList: payload
    };
  }

  if (type === 'AUTHORIZED_USERS_FETCH_ERROR') {
    return {
      ...state,
      authorizedUsersList: []
    };
  }
  return state;
};
