import React, { Component } from 'react';
import LegalHoldForm from 'components/CRUD/LegalHold/form/LegalHoldForm';
import actions from 'actions/LegalHold/LegalHoldFormActions';
import { connect } from 'react-redux';
import listActions from 'actions/LegalHold/LegalHoldListActions';
import { withRouter } from 'utils/withRouter';

class LegalHoldFormPage extends Component {
  state = {
    dispatched: false
  };

  componentDidMount() {
    const { dispatch, navigate } = this.props;
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
    const expireonUser = {
      firstName,
      lastName,
      email,
      userName
    };
    const isChat = false;
    if (this.isEditing() || this.isNotConfirmed()) {
      dispatch(actions.doFindContainers(navigate, isChat));
      dispatch(actions.doFindMessages(navigate));
      dispatch(actions.doFind(this.props.legalHoldId, expireonUser, navigate));
      dispatch(actions.doFindLegalHoldNames(this.props?.legalCaseId,isChat));
    } else {
      dispatch(actions.doFindContainers(navigate, isChat));
      dispatch(actions.doFindMessages(navigate));
      dispatch(actions.doFindLegalHoldNames(this.props?.legalCaseId,isChat));
    }
    this.setState({ dispatched: true });
  }

  doSubmit = (id, data) => {
    const { dispatch } = this.props;
    const legalCaseId = this.props.legalCaseId;
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
    if (this.isEditing()) {
      dispatch(
        actions.doUpdate(this.props.legalHoldId, {
          ...data,
          legalHoldId: this.props.legalHoldId,
          pageNumber: this.props.pageNumber,
          pageSize: this.props.pageSize,
          expireonUser: {
            firstName,
            lastName,
            email,
            userName
          }
        })
      );
    } else {
      let formData = { ...data };
      formData.legalHoldScope = undefined;
      dispatch(
        actions.doCreate(
          {
            ...formData,
            legalCaseId: legalCaseId
          },
          {
            expireonUser: {
              firstName,
              lastName,
              email,
              userName
            }
          }
        )
      ).then(() => {
        dispatch(
          listActions.doFetch({
            legalCaseId: legalCaseId,
            pageNumber: this.props.pageNumber,
            pageSize: this.props.pageSize
          })
        );
      });
    }
  };

  isEditing = () => {
    return this.props.isEditing;
  };

  isNotConfirmed = () => {
    return this.props.isNotConfirmed;
  };

  render() {
    return (
      <React.Fragment>
        {this.state.dispatched && (
          <LegalHoldForm
            saveLoading={this.props.saveLoading}
            findLoading={this.props.findLoading}
            currentUser={this.props.currentUser}
            record={this.isEditing() || this.isNotConfirmed() ? this.props.record : {}}
            containers={this.props.containers}
            isEditing={this.isEditing()}
            isNotConfirmed={this.isNotConfirmed()}
            onSubmit={this.doSubmit}
            history={this.props.history}
            legalHoldNames={this.props.legalHoldNames}
            messages={this.props.messages}
            dispatch={this.props.dispatch}
            currentTheme={this.props.currentTheme}
            legalHoldStats={this.props.legalHoldStats}
            legalCaseId={this.props.legalCaseId}
            openFormModal={this.props.openFormModal}
            currentStep={this.props.currentStep}
            pageNumber={this.props.pageNumber}
            pageSize={this.props.pageSize}
            selectedCustodiansList={this.props.selectedCustodiansList}
            selectedExternalCustodians={this.props.selectedExternalCustodians}
            allCustodiansList={this.props.allCustodiansList}
            userIdentities={this.props.userIdentities}
            openInternalSelectionModal={this.props.openInternalSelectionModal}
            temporaryCustodiansCount={this.props.temporaryCustodiansCount}
            createdLegalHoldId={this.props.createdLegalHoldId}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(store) {
  return {
    findLoading: store.LegalHold.form.findLoading,
    saveLoading: store.LegalHold.form.saveLoading,
    record: store.LegalHold.form.record,
    containers: store.LegalHold.form.containers,
    messages: store.LegalHold.form.messages,
    currentUser: store.auth.currentUser,
    rows: store.LegalHold.list.rows,
    legalHoldNames: store.LegalHold.form.legalHoldNames,
    currentTheme: store.layout.currentTheme,
    legalHoldStats: store.LegalHold.form.legalHoldStats,
    openFormModal: store.LegalHold.form.openFormModal,
    currentStep: store.LegalHold.form.currentStep,
    selectedCustodiansList: store.LegalHold.form.selectedCustodiansList,
    selectedExternalCustodians: store.LegalHold.form.selectedExternalCustodians,
    allCustodiansList: store.LegalHold.form.allCustodiansList,
    userIdentities: store.LegalHold.form.userIdentities,
    openInternalSelectionModal: store.LegalHold.form.openInternalSelectionModal,
    temporaryCustodiansCount: store.LegalHold.form.temporaryCustodiansCount,
    createdLegalHoldId: store.LegalHold.form.legalHoldId
  };
}

export default withRouter(connect(mapStateToProps)(LegalHoldFormPage));
